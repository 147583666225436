<template>
  <div class="main_wrapper product_list cart_page agent_landing plan_details informations" v-if="this.$router.name=='error'">
    <error-page></error-page>
  </div>
  <div class="main_wrapper product_list cart_page agent_landing plan_details informations" v-else-if="this.$router.name=='decline-reminder-email'">
    <decline-email />
  </div>
  <div class="main_wrapper product_list cart_page agent_landing plan_details informations" v-else>
<!--    <ben-header-area v-if="include_extra"></ben-header-area>-->
<!--    <header-area v-if="include_old"></header-area>-->
    <router-view></router-view>
    <open-page v-if="landing_type == 'user' && isOpenEnrollment"></open-page>
    <agent-open-page v-else-if="landing_type == 'agent' && isOpenEnrollment"></agent-open-page>
    <group-open-page v-else-if="landing_type == 'group' && isOpenEnrollment"></group-open-page>
<!--    <support-area v-if="include_extra"></support-area>-->
<!--    <ben-footer-area v-if="include_extra"></ben-footer-area>-->
<!--    <footer-area v-if="include_old"></footer-area>-->
  </div>
</template>

<script>
  // import Header from '../components/Header'
  // import BenAdvanceHeader from '../components/BenHeader'
  // import Footer from '../components/Footer'
  import ErrorPage from '../components/404'
  import DeclineEmail from '../components/DeclineEmail.vue'

  // import BenFooter from '../components/BenFooter'
  // import Support from "../components/Support"
  import OpenPage from "../views/OpenPage"
  import AgentOpenHome from "../views/AgentOpenHome";
  import GroupOpenHome from "../views/GroupOpenHome";
  import axios from "axios";
  export default {
    name: "Home",
    components: {
      // 'ben-header-area': BenAdvanceHeader,
      // 'header-area': Header,
      // 'footer-area': Footer,
      // 'ben-footer-area': BenFooter,
      // 'support-area' : Support,
      'open-page' : OpenPage,
      'agent-open-page' : AgentOpenHome,
      'group-open-page' : GroupOpenHome,
      'error-page' : ErrorPage,
      'decline-email': DeclineEmail
    },
    data: function() {
      return {
        landing_type : '',
        include_extra: true,
        include_old: false,
        portalId: null,
        siteRedirection: null,
        eprocess: '',
        isRepVerified: false,
        isGroupVerified: false
      }
    },
    computed: {
      isOpenEnrollment() {
        if(this.$route.name == "main") {
            return true;
        } else {
          return false
        }
      },
      isOpenCommonEnrollment () {
        if(this.$route.name == "main") {
          if (this.$route.params.landing) {
            return false;
          } else {
            return true;
          }
        } else {
          return false
        }
      }
    },
    mounted() {
      console.log('parent called');
      let app = this;
      app.landing_type = window.localStorage.getItem('open_type');
      app.include_extra = app.$route.name !== 'error' && app.$route.name !== 'continue-enrollment';
      app.include_old = app.$route.name === 'continue-enrollment';
      app.fromSite = window.localStorage.getItem('fromSite');
      app.planId = window.localStorage.getItem('planId');
      app.siteRedirection = window.localStorage.getItem('siteRedirection');
      app.eprocess = window.localStorage.getItem('eprocess');
      app.isRepVerified = window.localStorage.getItem('isRepVerified');
      app.isGroupVerified = window.localStorage.getItem('isGroupVerified');
      app.checkUsagRedirectionFlow();
      app.checkForElevateTowellnessLink();
      app.checkForElevateWellnessRedirectionDirectFlow();
    },
    methods: {
      checkForElevateTowellnessLink () {
        let app = this;
        if ((app.fromSite === 'elevate-direct' && app.landing_type === 'group') || (app.fromSite === 'elevate' && app.landing_type === 'group')) {
          axios.get(process.env.VUE_APP_API_BASE+'/get-elevate-info')
              .then(function (response) {
                if (response.data.status == 'success') {
                     app.portalId = response.data.data.portal_id
                     window.localStorage.setItem('portal_id', app.portalId);
                     app.$bus.$emit('renderElevateInfoDirect')
                }
              })
              .catch(function (error) {
                console.log(error);
              });
         // this.$router.push('/');
        }
      },
      checkUsagRedirectionFlow () {
        // Methods main purpose is to check usag site and restrict some route for usag and redirect to cart page.
        let app = this
        if (app.siteRedirection == 'usag') {
          if (app.$route.name == 'main' || app.$route.name == 'plan' || app.$route.path == '/home'|| app.$route.path == '/' || app.$route.path == '/agent-home' ||
              app.$route.path == '/plan-coverage' || app.$route.path == '/agent-home-direct' || app.$route.path == '/website' || app.$route.path == '/agent-select-member' ||
              app.$route.path == '/wishlist' || app.$route.path == '/plans' || app.$route.path == '/group-home' || app.$route.path == '/group-select-member' ||
              app.$route.path == '/member-home' || app.$route.path == '/BenAdvanceHome' || app.$route.path == '/referrals' || app.$route.path == '/external' ||
              app.$route.path == '/recommend-list'  || app.$route.path == '/404') {
            app.$router.push('/cart')
          }
        }
      },
      checkForElevateWellnessRedirectionDirectFlow () {
        let app = this
        if (app.fromSite == 'elevate-direct') {
          if (app.$route.name == 'main' || app.$route.name == 'plans ' || app.$route.path == '/' || app.$route.path == '/home' || app.$route.path == '/agent-home' ||
              app.$route.path == '/plan-coverage' || app.$route.path == '/agent-home-direct' || app.$route.path == '/website' || app.$route.path == '/agent-select-member' ||
              app.$route.path == '/wishlist' || app.$route.path == '/plans' || app.$route.path == '/group-home' || app.$route.path == '/group-select-member' ||
              app.$route.path == '/member-home' || app.$route.path == '/BenAdvanceHome' || app.$route.path == '/referrals' || app.$route.path == '/external' ||
              app.$route.path == '/recommend-list' || app.$route.path == '/404') {
            app.$router.push(`/plan/${app.planId}/elevate-direct-group)`)
          }
        }
      }
    }
  }
</script>
